import React, {Component} from 'react';
import PropTypes from 'prop-types'; 
import axios from 'axios'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export default (WrappedComponent, loadSingleTime) => {
    return class extends Component {
        static propTypes = {
            name: PropTypes.string, 
            url: PropTypes.string,
            setList: PropTypes.func,
        };
    
        constructor(props) {
            super();
        }

        // Fetchrecords from database
        fetchFromDatabase(url) {
            let list = []
            axios({	
                method: 'get',
                url: apiBaseUrl + url,
                })
            .then(response => {
                list = response.data?response.data.result?response.data.result:[]:[];
                this.props.setList(this.props.name, list);
            })
            .catch(e => {
                console.log('(function: functions/fetchList) URL:', url, 'eror:', e);
                this.props.setList(this.props.name, []);
            });
        }    
    
        componentDidMount () {
            this.fetchFromDatabase(this.props.url)
        }  
        
        componentWillReceiveProps(nextProps) {
            if (this.props.url !== nextProps.url)  {
                this.fetchFromDatabase(nextProps.url)
            } 
        }
        render () {
            return(<WrappedComponent  {...this.props} />)    
        }    
    }  
}


