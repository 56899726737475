import React, { useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShowList from "../components/view/ShowList"

const GENDER = {MALE:'MALE', FEMALE:'FEMALE'}


const initialValues =  {fruit:'coconut'}

const TEXTS = {
  ERROR_NAME:{EN:'Name must only contain valid chars and blanks and - (no numbers)'  }
}

const language = 'EN';
const namePattern ="^([^0-9&()#?=]*)$"


const Page = () => {
  const [values, setValues] = useState(initialValues);
  const handleInputChange = e => {
    setValues({...values, [e.target.name]: e.target.value})
  }
  const handleSubmit = e => {
    e.preventDefault()
    alert(`values: { ${Object.entries(values).map(it => it[0] + ':' + it[1])} }`)
    setValues(initialValues)
  }
  const clearFields = e => {
    e.preventDefault()
    setValues(initialValues)
  }
  return(
    <Layout>
      <SEO title="Page six" />
      <form onSubmit={handleSubmit}>
        <label>
          First name
          <input
            type="text"
            name="firstName"
            pattern={namePattern}
            value={values.firstName?values.firstName:''}
            required
            onChange={e=>handleInputChange(e)}
          />
        </label>
        <label>
          Last name
          <input
            type="text"
            name="lastName"
            pattern={namePattern} 
            title={TEXTS.ERROR_NAME[language]}
            value={values.lastName?values.lastName:''}
            required
            onChange={e=>handleInputChange(e)}
          />
        </label>
        <label>
          email
          <input
            type="email"
            name="email"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$"
            size="30" 
            value={values.email?values.email:''}
            onChange={e=>handleInputChange(e)}
            required
          />
        </label>
        <p/>
          <label>Gender:
            Male:<input type='radio' default checked={values.gender === GENDER.MALE} onClick={() => setValues({...values, gender:GENDER.MALE})} />
            &nbsp;
            Female:<input type='radio' checked={values.gender === GENDER.FEMALE} onClick={() => setValues({...values, gender:GENDER.FEMALE})} />
          </label>
        <p/>
          <label>Xxxx: 
            <input type='checkbox' name={'xxxx'} checked={values.xxxx?true:false} onClick={() => setValues({...values, xxxx:values.xxxx?undefined:true})} />
          </label>
          &nbsp;
          <label>Yyyy
            <input type='checkbox' name={'yyyy'} checked={values.yyyy?true:false} onClick={() => setValues({...values, yyyy:values.yyyy?undefined:true})} />
          </label>
        <p/>
          <label>Fruit: 
            <select name={'fruit'} value={values.fruit} onChange={handleInputChange} onBlur={handleInputChange}>          
              <option value="grapefruit">Grapefruit</option>
              <option value="lime">Lime</option>
              <option value="coconut">Coconut</option>
              <option value="mango">Mango</option>
            </select>
          </label>
        <p/>
        <button type="submit">Submit</button>
        <button onClick={(e)=>clearFields(e)}>Clear</button>
      </form>      <h1>Hi from the sixth page</h1>
      <ShowList name="teacher" url={'/teacher'} />
      <ShowList name="images" url={'/listImages?rootdir=/knud/images'} />
      <ShowList name="imagesData" url={'/listImagesData?rootdir=/knud/images'} />
    </Layout>
  )
}

export default Page

//<ShowList name="texts" url={'/getTexts'} />
