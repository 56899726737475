import React, {useState, useEffect} from "react"
import { connect } from 'react-redux'
import withListFromStore from '../wrapped/withListFromStore'
import {setList, addToList, toggleIndexInList, changeRowByIndexInList, changeRowByIdInList, removeRowIdFromList} from '../../state/list'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';

var increment = 1
const ignoreKeys = ['id', 'creaTimestamp', 'updTimestamp']
const ViewList = (list, remove, add) => {
    const [row, setRow] = useState({})  
    const idExists=list?list[0]?list[0].id?true:false:false:false
    const handleSubmit = (e) => {
        let rowAdd = {id:'A' + increment++, ...row}
        add(rowAdd) 
        setRow({})
        e.preventDefault()
    }    
    return(
    list === undefined?
        <h1>List undefined</h1> 
    :list.length === 0?
        <h1>List length is 0</h1> 
    :
        <form onSubmit={(e)=>handleSubmit(e)}>
            <table style={{width:'80%', margin:'auto', fontSize:'small', overflow:'auto'}}>
                <thead>
                    {Object.keys(list[0]).map(key => <th>{key}</th>)}
                    {idExists?<th>Add/Remove</th>:null}
                </thead>
                <tbody>
                        <tr>
                            {Object.keys(list[0]).map(key => 
                                <td> 
                                    {ignoreKeys.includes(key)?null
                                    :<input type='text' size="15" placeholder={key} name={key} value={row[key]?row[key]:''} required onChange={e=>setRow({...row, [e.target.name]:e.target.value})} /> 
                                    }
                                </td>
                            )}    
                            <td><IconButton type="submit"><AddCircleIcon /></IconButton></td>  
                        </tr>        
                        {list.map(it => 
                            <tr>
                                {Object.values(it).map(val =>  <td>{val}</td>)}
                                <td><IconButton onClick = {()=>remove(it.id)} type="submit"><DeleteForeverIcon /></IconButton></td>  
                            </tr>        
                        )}  
                </tbody>
            </table>    
        </form>
    )    
}

const Test = (props) => {
    const [count, setCount] = useState(0)  
    const list = props.getList(props.name)
    if (count === 0  && list.length >0) {
        props.addToList(props.name, list[list.length - 1]) /* Duplicate last element in list, to test add function */
        props.toggleIndexInList(props.name, 0) /* Duplicate last element in list, to test add function */
        setCount(count+1)
    } else if (count===1) {
        props.toggleIndexInList(props.name, 0) /* Duplicate last element in list, to test add function */
        setCount(count+1)
    } else if (count===2) {
        props.changeRowByIndexInList(props.name, 0, {dayOfWeek:9}) /* Duplicate last element in list, to test add function */
        setCount(count+1)
    } else if (count===3) {
        props.changeRowByIdInList(props.name, {id:'135', dayOfWeek:8}) /* Duplicate last element in list, to test add function */
        setCount(count+1)
    } else if (count===4) {
        props.removeRowIdFromList(props.name, '138') /* Duplicate last element in list, to test add function */
        setCount(count+1)
    } 
}

const ViewListAsTable = (props) => {
    const remove = (id) => props.removeRowIdFromList(props.name, id)
    const add = (row) => props.addToList(props.name, row)
    console.log('ViewListAsTable props', props, 'name', props.name, 'list',  props.getList(props.name))
    const list = props.getList(props.name)
    // Test(props);
    return(ViewList(list, remove, add))
}
            
const mapStateToProps = (state) => {
    return {
        getList: (name) => 
        {
            console.log('mapStateToProps state', state, 'name', name, `state.${name}.list`, state[name].list)
            return(state[name].list)
        }
    }
}    

// Map the dispatch to onMyClick
const mapDispatchToProps = (dispatch) => {
    return {
        setList: (name, list) => {dispatch(setList(name, list))},
        addToList: (name, obj) => {dispatch(addToList(name, obj))},
        toggleIndexInList: (name, ix) => {dispatch(toggleIndexInList(name, ix))},
        changeRowByIndexInList: (name, ix, row) => {dispatch(changeRowByIndexInList(name, ix, row))},
        changeRowByIdInList: (name, row) => {dispatch(changeRowByIdInList(name, row))},
        removeRowIdFromList: (name, id) => {dispatch(removeRowIdFromList(name, id))},
    }        
}

export default  connect( 
    mapStateToProps,
    mapDispatchToProps,
) (withListFromStore(ViewListAsTable, true));    
